import React from "react";
import PropTypes from "prop-types";

import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { PageTemplate } from "./page";
function IndexPage({ data }) {
  const { frontmatter, html } = data.markdownRemark;
  return (
    <Layout fixedHeader={frontmatter.image ? true : false}>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title={frontmatter.title}
      />

      <PageTemplate
        title={frontmatter.title}
        html={html}
        image={frontmatter.image}
        intro={frontmatter.intro}
        parent={{}}
        childPages={[]}
        siblings={[]}
      />
      <div className="container">
        <ul className="py-12 list-none md:flex">
          {frontmatter.widgets.map((w, i) => (
            <li
              key={i}
              className="relative w-full p-2 list-none md:w-1/4 group mb-8"
            >
              <div className="embed-responsive aspect-ratio-4/3">
                {w.image && (
                  <img
                    src={w.image}
                    alt={w.title}
                    className="object-cover embed-responsive-item"
                  />
                )}
                <span className="absolute inset-0 flex items-center justify-center text-white transition duration-300 border-white opacity-0 bg-gradient-transparent group-hover:opacity-100 "></span>
              </div>
              <div>
                <h4 className="pt-4">
                  <span className="inline-block pb-4 mb-2 border-b-4 border-black ">
                    {w.title}
                  </span>
                </h4>
                <p>{w.text}</p>
              </div>
              <Link className="absolute inset-0" to={w.link}></Link>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  );
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      html: PropTypes.string,
    }),
  }),
};

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image
        intro
        widgets {
          title
          text
          link
          image
        }
      }
      html
    }
  }
`;

export default IndexPage;
